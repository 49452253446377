import React from 'react';
import List from 'components/List';

const Experience = () => {
    const dataList = [
        { 
            title: 'Speaker',
            details: 'TEDx'
        },
        { 
            title: 'Speaker',
            details: 'UCL Denmark'
        },
        { 
            title: 'Speaker',
            details: 'University of Bucharest, Advertising and Digital Communication Master\'s'
        },
        { 
            title: 'Hackathon',
            details: 'Microsoft Kinect, 2nd Place (Amsterdam)'
        },
        { 
            title: 'Hackathon',
            details: 'Dutch Open, Special Mention (Amsterdam)'
        },
        { 
            title: 'FWA Awards',
            details: '2x MINI, Mercedes-Benz'
        },
        { 
            title: 'FWA Jury',
            details: 'Since 2018'
        },
        { 
            title: 'Founder',
            details: 'Soseta3'
        },
        { 
            title: 'Co-Founder',
            details: 'Terci&Yummy, Moments&Me, ROMB Studio'
        }
    ];

    return (
        <List title="Experience" data={dataList} />
    );
};

export default Experience;
