import React, { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from "@gsap/react";
import profilepic from '../../assets/images/bogdanferariu-profile-photo.png';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Intro = () => {
  const container = useRef();
  const refImg = useRef();
  useGSAP(() => {
    ScrollTrigger.create({
      trigger: container.current,
      start: "0 0",
      end: "175%",
      pin: true,
      pinSpacing: false,
      // onToggle: (self) => console.log("toggled, isActive:", self.isActive),
      // onUpdate: (self) => {
      //   console.log(
      //     "progress:",
      //     self.progress.toFixed(3),
      //     "direction:",
      //     self.direction,
      //     "velocity",
      //     self.getVelocity()
      //   );
      // },
      markers: false
    });
  }, {container}); // <-- scope is for selector text (optional)

  return (
    <div className='intro-wrapper'>
        <div className='intro-wrapper-textwrapper' ref={container}>
          <h1>
            <span className="word-wrapper">
              <span>Creative+</span>
            </span>
            <span className="word-wrapper">
              <span>Technologist</span>
            </span>
          </h1>
          <h2>I'm Bogdan Ferariu, a creative and technologist with 10 years expertise in building digital experiences for brands in automotive, luxury, sports and technology. Ended up (co)starting my own brands in clothing (socks), healthy food and homeware.<br /><br />My day-to-day activity is not just about writing code or coming up with ideas, but in fact, it is more about helping brands grow and achieve their goals.</h2>
        </div>

        <img ref={refImg} src={profilepic} alt="Bogdan Ferariu profile picture" />
    </div>
  );
};

export default Intro;
