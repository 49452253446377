import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import nobleImg from '../../assets/images/projects/bogdanferariu-noblecards-video.mp4';
import tzImg from '../../assets/images/projects/bogdanferariu-tierzero-video.mp4'
const Project = ({ imgsrc, title, description, industry, location, url, site, color, nextColor, index }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handleToggle = () => {
        const video = document.getElementById(`projectVideo-${index}`);
    
        if (isPlaying) {
          video.pause();
        } else {
          video.play();
        }
    
        setIsPlaying(!isPlaying);
    };

    return (
        <div className='project-wrapper'>
            <div className='project-wrapper-video'>
                <button onClick={handleToggle} data-playing={isPlaying}>
                <svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="none">
                    <circle cx="96" cy="96" r="96" fill="#E6E3A3" fill-opacity=".2" />
                    <path fill="#E6E3A3" d="M76.04 62.793c0-2.813 3.107-4.517 5.48-3.004l51.367 32.765c2.169 1.384 2.203 4.54.063 5.968l-51.272 34.247c-2.368 1.582-5.543-.116-5.543-2.963V90.982l-.096-16.177V62.793Z" />
                </svg>
                </button>
                <video
                    id={`projectVideo-${index}`} 
                    width="100%" 
                    height="100%" 
                    muted 
                    loop 
                    data-playing={isPlaying}
                    onClick={handleToggle}
                >
                <source src={imgsrc} type="video/mp4" />
                </video>
            </div>

            <div className='project-row'>
                <h3>{title}</h3>
                <h4>{industry}</h4>
                <h5>{location}</h5>
            </div>
            <div className='project-row--divider'></div>
            <div className='project-row'>
                <h6>{description}</h6>
            </div>
            <span className='project-url'>
                <a href={url} target="_blank">
                    <div className='arrow-wrapper arrow-wrapper--large'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="56" viewBox="0 0 55 56" fill="none">
                            <path d="M31.9443 8.58563C32.7286 7.80793 33.995 7.81335 34.7727 8.59773L52.7357 26.715C53.5134 27.4994 53.508 28.7657 52.7236 29.5434L34.6479 47.4653C33.8635 48.243 32.5972 48.2376 31.8195 47.4532L28.3461 43.95C27.5684 43.1656 27.5738 41.8993 28.3582 41.1216L33.4401 36.0829C34.7035 34.8302 33.8251 32.6752 32.046 32.6627L6.54044 32.483C5.446 32.4753 4.56103 31.5894 4.55456 30.4949L4.52449 25.4016C4.51794 24.2926 5.41505 23.39 6.52409 23.3898L32.1452 23.3851C33.9227 23.3848 34.8166 21.2392 33.5651 19.977L28.4743 14.8425C27.6966 14.0581 27.702 12.7918 28.4864 12.0141L31.9443 8.58563Z" fill="#FFFFFF"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="56" viewBox="0 0 55 56" fill="none">
                            <path d="M31.9443 8.58563C32.7286 7.80793 33.995 7.81335 34.7727 8.59773L52.7357 26.715C53.5134 27.4994 53.508 28.7657 52.7236 29.5434L34.6479 47.4653C33.8635 48.243 32.5972 48.2376 31.8195 47.4532L28.3461 43.95C27.5684 43.1656 27.5738 41.8993 28.3582 41.1216L33.4401 36.0829C34.7035 34.8302 33.8251 32.6752 32.046 32.6627L6.54044 32.483C5.446 32.4753 4.56103 31.5894 4.55456 30.4949L4.52449 25.4016C4.51794 24.2926 5.41505 23.39 6.52409 23.3898L32.1452 23.3851C33.9227 23.3848 34.8166 21.2392 33.5651 19.977L28.4743 14.8425C27.6966 14.0581 27.702 12.7918 28.4864 12.0141L31.9443 8.58563Z" fill="#FFFFFF"/>
                        </svg>
                    </div>
                    {site}
                </a>
            </span>
        </div>
    );
};

const Projects = () => {
    const projectsWrapperRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
          projectsWrapperRef.current,
          {
            backgroundColor: '#E46D86', // Set the initial background color
          },
          {
            scrollTrigger: {
              trigger: projectsWrapperRef.current,
              start: 'top bottom-=100',
              end: 'bottom top+=100',
              scrub: true,
            },
            backgroundColor: '#F58E2C', // Set your desired transition end color
            ease: 'power2.inOut',
          }
        );
      }, []);

    const projectsArr = [
        {
            imgsrc: nobleImg,
            title: "Noble Gallery",
            description: "ReactJS and web3 frontend for the Noble Gallery, in collaboration with the talented team of CP Studio worldwide.",
            industry: "NFT Gallery",
            location: "USA, worldwide",
            url: "https://noblegallery.com",
            site: "noblegallery.com",
            color: "#E46D86",
            nextColor: "#ED7E5A"
        },
        {
            imgsrc: tzImg,
            title: "Tier Zero",
            description: "Full dev for the inspiring bunch of TZ. Built on VueJS and Contentful. Art direction and design by LS.W.",
            industry: "Studio Website",
            location: "Toronto",
            url: "https://tierzero.co",
            site: "tierzero.co",
            color: "#ED7E5A",
            nextColor: "#F58E2C"
        }
    ];    

    return (
    <div id="projects" className='projects-wrapper' ref={projectsWrapperRef}>
        { projectsArr.map((project, index) => (
            <Project 
                imgsrc={project.imgsrc} 
                title={project.title}
                description={project.description}
                industry={project.industry}
                location={project.location}
                url={project.url}
                color={project.color}
                site={project.site}
                nextColor={project.nextColor}
                index={index} 
            />
        ))}
    </div>
    );
};

export default Projects;
