import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

// client imgs 
import client1 from '../../assets/images/clients/client-1.png';
import client2 from '../../assets/images/clients/client-2.png';
import client3 from '../../assets/images/clients/client-3.png';
import client4 from '../../assets/images/clients/client-4.png';
import client5 from '../../assets/images/clients/client-5.png';
import client6 from '../../assets/images/clients/client-6.png';
import client7 from '../../assets/images/clients/client-7.png';
import client8 from '../../assets/images/clients/client-8.png';
import client9 from '../../assets/images/clients/client-9.png';
import client10 from '../../assets/images/clients/client-10.png';
import client11 from '../../assets/images/clients/client-11.png';
import client12 from '../../assets/images/clients/client-12.png';

const MAX_IMAGES = 12;
const MOUSE_STOP_DELAY = 500;

const Clients = () => {
    const [isStopped, setIsStopped] = useState(false);
    const [images, setImages] = useState([]);
    const imageUrls = [
      client1,
      client2,
      client3,
      client4,
      client5,
      client6,
      client7,
      client8,
      client9,
      client10,
      client11,
      client12
    ];

    // in viewport
    const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0.1,
    });
  
    const prevMousePosition = useRef({ x: 0, y: 0 });
    const mouseStopTimer = useRef(null);
  
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const dx = clientX - prevMousePosition.current.x;
      const dy = clientY - prevMousePosition.current.y;
  
      if (Math.abs(dx) >= 30 || Math.abs(dy) >= 30) {
        clearTimeout(mouseStopTimer.current); // Clear the previous timer
        setIsStopped(false);
  
        const randomImageUrl = imageUrls[Math.floor(Math.random() * imageUrls.length)];
        const newImage = { url: randomImageUrl, x: clientX, y: clientY };
        const updatedImages = [...images, newImage].slice(-MAX_IMAGES); // Limit to 20 images
        setImages(updatedImages);
        prevMousePosition.current.x = clientX;
        prevMousePosition.current.y = clientY;
      }
    };
  
    useEffect(() => {
      // Set a timer to log "stop" after X seconds of no mouse movement
      mouseStopTimer.current = setTimeout(() => {
        setIsStopped(!isStopped);
      }, MOUSE_STOP_DELAY);
  
      // Clear the timer when component unmounts or when mouse movement occurs
      return () => {
        clearTimeout(mouseStopTimer.current);
      };
    }, [images]); // Add images to the dependency array to reset the timer on new image additions
    
    // useEffect(() => {
    //   setImages([])
    // }, [isStopped])

    const handleImageAnimationEnd = (index) => {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    };

    return (
        <div id="clients" className='clients' ref={ref}>
            <h2>
              <span className='clients-word-wrapper' data-show={inView}>
                <span>I've done</span>
              </span>
            </h2>
            <h2>
              <span className='clients-word-wrapper' data-show={inView}>
                <span>work</span>
              </span>
            </h2>
            <h2>
              <span className='clients-word-wrapper' data-show={inView}>
                <span>for them</span>
              </span>
            </h2>

            <div className='clients-move clients-move--desktop' data-show={inView}>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
              <span>move that mouse</span>
            </div>

            <div className='clients-move clients-move--mobile' data-show={inView}>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
              <span>tap tap tap</span>
            </div>

            <div
                className='trail-img-wrapper'
                data-stopped={isStopped}
                onMouseMove={handleMouseMove}
                style={{
                    width: '100vw',
                    height: '100vh',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'none',
                }}
                >
                {images.map((image, index) => (
                    <img
                    key={index}
                    src={image.url}
                    alt="Trail Image"
                    style={{
                        position: 'absolute',
                        left: `${image.x - 103}px`, // Adjusted for centering
                        top: `${image.y - 103}px`, // Adjusted for centering
                    }}
                    onAnimationEnd={() => handleImageAnimationEnd(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Clients;
