import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Home from "screens/Home";

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<>Loading ...</>}>
        <Routes>
          <Route exact path={'/'} element={<Home />} />
          {/* <Route path={'/account'} element={<AdminScreen />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
