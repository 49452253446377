import React from 'react';
import { useInView } from 'react-intersection-observer';

const List = ({ title, data }) => {
    // in viewport
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    return (
    <div id="press" className='press-wrapper' ref={ref}>
        <h2 data-show={inView}>
            <span>{title}</span>
        </h2>

        <ul data-show={inView}>
            {data.map((item, index) => (
                <li key={index}>
                    <div className='press-item'>
                        <span className='press-title'>{item.title}</span>
                        {item.details &&
                            <span className={`press-article ${!item.link ? "press-article--wide" : ""}`}>{item.details}</span>
                        }
                        {item.img &&
                            <img src={item.img} alt="" />
                        }
                        {item.link &&
                            <a className='press-link' href={item.link} target="_blank">
                                <div className='arrow-wrapper arrow-wrapper--medium'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 55 56" fill="none">
                                        <path d="M31.9443 8.58563C32.7286 7.80793 33.995 7.81335 34.7727 8.59773L52.7357 26.715C53.5134 27.4994 53.508 28.7657 52.7236 29.5434L34.6479 47.4653C33.8635 48.243 32.5972 48.2376 31.8195 47.4532L28.3461 43.95C27.5684 43.1656 27.5738 41.8993 28.3582 41.1216L33.4401 36.0829C34.7035 34.8302 33.8251 32.6752 32.046 32.6627L6.54044 32.483C5.446 32.4753 4.56103 31.5894 4.55456 30.4949L4.52449 25.4016C4.51794 24.2926 5.41505 23.39 6.52409 23.3898L32.1452 23.3851C33.9227 23.3848 34.8166 21.2392 33.5651 19.977L28.4743 14.8425C27.6966 14.0581 27.702 12.7918 28.4864 12.0141L31.9443 8.58563Z" fill="#0E2F2B"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 55 56" fill="none">
                                        <path d="M31.9443 8.58563C32.7286 7.80793 33.995 7.81335 34.7727 8.59773L52.7357 26.715C53.5134 27.4994 53.508 28.7657 52.7236 29.5434L34.6479 47.4653C33.8635 48.243 32.5972 48.2376 31.8195 47.4532L28.3461 43.95C27.5684 43.1656 27.5738 41.8993 28.3582 41.1216L33.4401 36.0829C34.7035 34.8302 33.8251 32.6752 32.046 32.6627L6.54044 32.483C5.446 32.4753 4.56103 31.5894 4.55456 30.4949L4.52449 25.4016C4.51794 24.2926 5.41505 23.39 6.52409 23.3898L32.1452 23.3851C33.9227 23.3848 34.8166 21.2392 33.5651 19.977L28.4743 14.8425C27.6966 14.0581 27.702 12.7918 28.4864 12.0141L31.9443 8.58563Z" fill="#0E2F2B"/>
                                    </svg>
                                </div>
                            </a>
                        }
                    </div>
                </li>
            ))}
        </ul>
    </div>
    );
};

export default List;
