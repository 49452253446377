import React, { useEffect } from 'react';

import Nav from 'components/Nav';
import Intro from 'components/Intro';
import Gallery from 'components/Gallery';
import Projects from 'components/Projects';
import Clients from 'components/Clients';
import Press from 'components/Press';
import Footer from 'components/Footer';

import Lenis from '@studio-freight/lenis';
import Experience from 'components/Experience';


const Home = () => {
    useEffect(() => {
        const lenis = new Lenis({
            duration: 2,
        })
        function raf ( time ) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    }, [])


    return (
        <>
            <Nav />
            <Intro />
            <Experience />
            <Clients />
            <Gallery />
            <Projects />
            <Press />
            <Footer />
        </>
    )
}

export default Home;