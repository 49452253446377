import React from 'react';
import List from 'components/List';

import businessMagTerci from '../../assets/images/press/bogdanferariu-terciyummy-businessmag.jpg';
import businessMagS3 from '../../assets/images/press/bf-businessmag.jpg';
import apropoTv from '../../assets/images/press/bf-apropotv.jpg';
import radioGuerilla from '../../assets/images/press/bf-interview.jpeg';
import fwa from '../../assets/images/press/bf-fwa.png';
import iqads from '../../assets/images/press/bf-interview.jpeg';
import mbenz from '../../assets/images/press/mb-aliciakeys.jpeg';

const Press = () => {
    const dataList = [
        { 
            title: 'BUSINESS MAGAZIN', 
            details: 'PRINT AND ONLINE, 2022.', 
            link: 'https://www.businessmagazin.ro/analize/comert/neaos-la-mic-dejun-cum-au-reusit-doi-antreprenori-romani-sa-20492258',
            img: businessMagTerci
        },
        { 
            title: 'BUSINESS MAGAZIN', 
            details: 'PRINT AND ONLINE, 2019.', 
            link: 'https://www.businessmagazin.ro/actualitate/in-cautarea-sosetei-ratacite-18383399',
            img: businessMagS3  
        },
        { 
            title: 'APROPO TV', 
            details: 'TV, 2019.', 
            link: 'https://www.facebook.com/apropo.tv/videos/533323713930594/UzpfSTExNzIzNzE3OTQ6MTAyMjA5MzU1ODM1OTczMTE/',
            img: apropoTv 
        },
        { 
            title: 'RADIO GUERILLA', 
            details: 'RADIO, 2019.', 
            link: 'https://www.guerrillaradio.ro/revista-guerrilla/qreator-bogdan-ferariu-5-noiembrie-2019/',
            img: radioGuerilla  
        },
        { 
            title: 'FWA EDITORIAL', 
            details: 'ONLINE, 2019.', 
            link: 'https://thefwa.com/fwa-jury/bogdan-ferariu',
            img: fwa  
        },
        { 
            title: 'IQADS / SMARK', 
            details: 'ONLINE, 2020.', 
            link: 'https://www.smark.ro/articol/53269/bogdan-ferariu-soseta3-am-decis-sa-nu-tinem-toata-bucuria-pentru-noi-am-creat',
            img: iqads 
        },
        { 
            title: 'W&V DE, MERCEDES-BENZ', 
            details: 'ONLINE, 2020.', 
            link: 'https://www.wuv.de/marketing/mercedes_zeigt_um_wen_sich_alicia_keys_sorgt',
            img: mbenz 
        }
    ];

    return (
        <List title="Press" data={dataList} />
    );
};

export default Press;
